import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import {
  Card,
  Form,
  Input,
  Button,
  Select,
  InputNumber,
  Space,
  Divider,
  Upload,
  Alert
} from 'antd';

import { SaveOutlined, UploadOutlined } from '@ant-design/icons';

// Services
import deviceService from 'services/device.service';
import brandService from 'services/brand.service';

// Condiguration
import configuration from 'config';

// Utils
import { getColor, getStorage, getQuestions } from 'utils/options';
import {
  beforeUpload,
  fakeUploader,
  fileUpload,
  handleImageChange
} from 'utils/file.util';

// Resources
import {
  storageOptionsData,
  colorOptionsData
} from 'assets/data/storage-color-options';

function PhoneForm() {
  // State
  const [brands, setBrands] = useState();
  const [selectedStorageOptions, setSelectedStorageOptions] = useState();
  const [storageOptions, setStorageOptions] = useState(storageOptionsData);
  const [colorOptions, setColorOptions] = useState(colorOptionsData);
  const [questions, setQuestions] = useState();
  const [device, setDevice] = useState({});
  const [fileList, setFileList] = useState([]);

  // Hooks
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation('pages');

  // Handlers
  const updateStorageOptions = (keys) => {
    const storageOptions = storageOptionsData.filter(
      (item) => !keys.includes(item.label)
    );
    setStorageOptions(storageOptions);
  };

  const updateColorOptions = (keys) => {
    const colorOptions = colorOptionsData.filter(
      (item) => !keys.includes(item.label)
    );
    setColorOptions(colorOptions);
  };

  const fetchDevice = async () => {
    if (!id) {
      return;
    }

    const res = await deviceService.getDevice(id);

    if (res && res.devices) {
      const deviceData = res.devices;
      const storageOptions = getStorage(deviceData);
      const colorOptions = getColor(deviceData);
      const questionOptions = getQuestions(deviceData);

      const data = {
        id: deviceData.id,
        brand_name: deviceData.brand.name,
        name: deviceData.name,
        model: deviceData.model,
        full_name: `${deviceData.brand.name} ${deviceData.name}${deviceData.model}`,
        image_path: deviceData.image.path,
        storage: storageOptions.keys,
        color: colorOptions
      };

      storageOptions.options.forEach((i) => {
        data[Object.keys(i)] = i[Object.keys(i)];
      });
      questionOptions.forEach((i) => {
        data[Object.keys(i)] = i[Object.keys(i)];
      });

      setDevice(data);
      updateStorageOptions(storageOptions.keys);
      updateColorOptions(colorOptions);
      setSelectedStorageOptions(storageOptions.selectedOptions);
      setFileList([
        {
          uid: '-1',
          name: data.full_name,
          status: 'done',
          url: configuration().apiUrl + data.image_path
        }
      ]);
      setQuestions(deviceData.brand.questions);

      form.resetFields();
    }
  };

  const fetchBrands = async () => {
    const data = await brandService.getBrands();
    setBrands(data.rows);
  };

  const setStoragePricingOptions = (data) => {
    const storage = selectedStorageOptions || [];

    const newOptions = [];

    data.forEach((value) => {
      const index = storage.findIndex((item) => item.value === value);

      if (index < 0) {
        newOptions.push({ name: `storage_${value}`, value, price: 0 });
        return;
      }

      newOptions.push(storage[index]);
    });

    setSelectedStorageOptions(newOptions);
  };

  const brandChanged = (data) => {
    const index = parseInt(data.key);

    setQuestions(brands[index].questions);
  };

  const getBrandId = (value) => {
    if (!isNaN(value)) {
      return value;
    }

    const index = brands.findIndex((item) => item.name === value);
    return brands[index].id;
  };

  const formatStorageOptions = (data) => {
    const options = data.storage;

    const formattedOptions = options.map((item) => {
      const name = `storage_${item}`;
      return { name: item, price: data[name] };
    });

    return { data: formattedOptions };
  };

  const formatDeviceCharges = (data) => {
    const q = [];
    for (const key in data) {
      if (key.includes('question')) {
        const keys = key.split('question_')[1].split('_answer_');
        q.push({
          question_id: keys[0],
          answer_id: keys[1],
          charges: data[key]
        });
      }
    }

    return q;
  };

  const deviceSubmittion = async (values) => {
    const data = new FormData();

    data.append('brand_id', getBrandId(values.brand_name));
    data.append('name', values.name);
    data.append('model', values.model);
    data.append(
      'options',
      JSON.stringify([
        { name: 'colors', options: values.color },
        { name: 'storage', options: formatStorageOptions(values) }
      ])
    );

    data.append('charges', JSON.stringify(formatDeviceCharges(values)));

    if (values.image) {
      data.append('image', values.image.file.originFileObj);
    }

    const path = id ? `/devices/${id}` : '/devices';

    await fileUpload({ path, data });
    form.resetFields();
    navigate('/');
  };

  useEffect(() => {
    fetchBrands();
    fetchDevice();
  }, []);

  return (
    <Card
      style={{ paddingTop: 25, alignItems: 'center' }}
      bordered={false}
      title={`${id ? t('partner.edit') : t('common:add')} ${t('phone.device')}`}
    >
      <Form
        form={form}
        encType="multipart/form-data"
        initialValues={{ ...device }}
        style={{ maxWidth: 900, margin: 'auto', textAlign: 'center' }}
        layout="vertical"
        name="general"
        onFinish={deviceSubmittion}
        scrollToFirstError
      >
        <Form.Item
          name="brand_name"
          label={t('forms:label.brand')}
          rules={[
            { required: true, message: t('forms:requiredMessage.selectBrand') }
          ]}
        >
          <Select
            disabled={id}
            onChange={(value, data) => brandChanged(data, value)}
          >
            {brands
              && brands.map((brand, index) => (
                <Select.Option
                  value={brand.id}
                  key={`${brand.id}-${index + 1}-${brand.name}`}
                >
                  {brand.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="name"
          label={t('forms:label.name')}
          rules={[
            { required: true, message: t('forms:requiredMessage.deviceName') }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="model"
          label={t('forms:label.model')}
          rules={[
            {
              required: true,
              message: t('forms:requiredMessage.model')
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="storage"
          label={t('forms:label.storageOptions')}
          rules={[
            {
              required: true,
              message: t('forms:requiredMessaage.storageOptions')
            }
          ]}
        >
          <Select
            mode="tags"
            style={{ width: '100%' }}
            placeholder={t('forms:label.storageOptions')}
            onChange={setStoragePricingOptions}
            options={storageOptions}
          />
        </Form.Item>

        <Space wrap>
          {selectedStorageOptions
            && selectedStorageOptions.map((item, index) => (
              <React.Fragment key={`${item.name}-${index + 1}-${item.value}`}>
                <Form.Item
                  name={item.name}
                  label={`${t('forms:label.priceFor')}: ${item.value}`}
                  rules={[
                    {
                      required: true,
                      message: ` ${t(
                        'forms:requiredMessage.storagePriceFor'
                      )} ${item.value}!`
                    }
                  ]}
                >
                  <InputNumber />
                </Form.Item>
                {selectedStorageOptions.length !== index + 1 && (
                  <Divider type="vertical" />
                )}
              </React.Fragment>
            ))}
        </Space>

        <Form.Item
          name="color"
          label={t('forms:label.colorOptions')}
          rules={[
            { required: true, message: t('forms:requiredMessage.colorOptions') }
          ]}
        >
          <Select
            mode="tags"
            style={{ width: '100%' }}
            placeholder={t('forms:label.colorOptions')}
            options={colorOptions}
          />
        </Form.Item>

        <Form.Item
          name="image"
          rules={[
            {
              required: fileList.length === 0,
              message: t('forms:requiredMessage.uploadDeviceImg')
            }
          ]}
        >
          <Upload
            name="image"
            listType="picture"
            customRequest={fakeUploader}
            showUploadList
            onChange={(info) => handleImageChange(info, setFileList)}
            beforeUpload={beforeUpload}
            fileList={fileList}
          >
            <Button icon={<UploadOutlined />}>{t('heroImages.upload')}</Button>
          </Upload>
        </Form.Item>

        <Divider orientation="left">{t('phoneForm.questions')}</Divider>
        {questions ? (
          questions.map((item) => (
            <React.Fragment key={`question_${item.id}`}>
              <p>
                *<b>{item.question}</b>
              </p>
              <Space direction="horizontal" wrap>
                {item.answers.map((i) => (
                  <Form.Item
                    key={`question_${item.id}_answer_${i.id}`}
                    name={`question_${item.id}_answer_${i.id}`}
                    label={i.answer}
                    rules={[
                      {
                        required: true,
                        message: t('forms:requiredMessage.priceForAnswer')
                      }
                    ]}
                  >
                    <InputNumber />
                  </Form.Item>
                ))}
              </Space>
            </React.Fragment>
          ))
        ) : (
          <Alert message={t('phoneForm.questionInfo')} type="info" />
        )}

        <Divider />
        <Button
          type="primary"
          htmlType="submit"
          icon={<SaveOutlined />}
          style={{ float: 'right' }}
        >
          {t('common:submit')}
        </Button>
      </Form>
    </Card>
  );
}

export default PhoneForm;
